
.loanReq-details{
  .ant-collapse-header{
      @apply flex justify-between items-center flex-row-reverse #{!important};
    }
  .panel-header{
    display: inline-flex;
    background: linear-gradient(270deg, #2b48f4, #34a5ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}
.loan-actions-confirm{
  .ant-popconfirm-buttons{
    @apply flex justify-center items-center #{!important};
    .ant-btn{
      @apply shadow-none #{!important};
    }
  }
  .ant-popconfirm-message-icon{
    @apply hidden #{!important};
  }
}
.loan-actions-confirm.accept-btn{
  .ant-btn-primary{
    @apply bg-caribbeanGreen-900 border-caribbeanGreen-900 #{!important};
  }
}
.loan-actions-confirm.reject-btn{
  .ant-btn-primary{
    @apply bg-emberGlow-900 border-emberGlow-900 #{!important};
  }
}

