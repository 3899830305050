.create-loan-container{
    .ant-form-item{
        @apply w-full #{!important}
    }
    .ant-form-item-control{
        @apply min-w-full #{!important}
    }
    .calcs-container{
        @apply mb-6 #{!important};
        .ant-form-item{
            @apply mb-0 #{!important}
        }
    }
}
