.borrower-edit-mode{
  .ant-tabs {
    @apply mt-8 bg-white py-4 px-6 rounded-10;
    input{
      @apply h-10 w-full
    }
    .ant-picker {
      @apply  w-full h-9
    }
    .ant-select-selector{
      @apply  h-10;
    } 
  }
  .ant-tabs-nav{
    @apply mb-8
  }
  .ant-btn[type="submit"] {
    @apply bg-primary text-white h-10 font-bold;
  }
  .company-docs-item{
    @apply bg-gray-100 mb-4 p-6 rounded-10
  }
}
.borrower-profile{
  .icons-container{
    .edit-icon,
    .wallet-icon{
      svg{
        @apply fill-mazarineBlue #{!important}
      }
    }
  }
}
.upload-container {
  margin-bottom: 20px;
  position: relative;
  .ant-upload-btn {
    padding: 6.08px 0 !important;
    border: none !important;
    border: 1px dashed #2b48f4 !important;
    //     border-radius: 95px !important;
  }

  .ant-upload {
    //    height: 81px !important;
    background: transparent !important;
    border: none;
    //  border: 1px dashed #2B48F4 !important;
    border-radius: 95px !important;
    width: 100% !important;

    span {
      // border: none !important;
    }
    
  }


  .import {
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #8a8595;
    margin-bottom: 0 !important;
  }



  .browse {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #2b48f4;
    margin-bottom: 0 !important;

    svg {
      margin-right: 8px;
    }
  }

  .upload-file-container {

    // position: relative;
    
    height: 100%;
    background: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    position: absolute;
    top: 0;
    margin-top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border: 1px solid #DCDDE5;
  }
}

.upload-container {
  .ant-upload-btn {
      padding: 14.1px 0 !important;
      border: none !important;
      border: 1px dashed #5B2CD3 !important;
      //     border-radius: 95px !important;
  }

  .ant-upload {
      //    height: 81px !important;
      background: transparent !important;
      border: none;
      //  border: 1px dashed #5B2CD3 !important;
      border-radius: 95px !important;
      width: 100% !important;

      span {
          // border: none !important;
      }
  }

  .import {
      font-weight: 500;
      font-size: 12px;
      //text-align: center;
      color: #8A8595;
      margin-bottom: 0 !important;
  }

  .browse {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #5B2CD3;
      margin-bottom: 0 !important;

      svg {
          margin-right: 8px;
      }
  }
}
.upload-file-container{
   h2 {
    font-size: 13px!important;
    color: #140a2b;
    margin: 0;
    margin-left: 10px;
    text-align: start!important;
    width: calc(100% - 5rem);
  }
  .import{
    text-align: start;
  }
}
.pdf-modal{
  .ant-modal-close{
    &:hover{
      @apply bg-transparent #{!important};
    }
  }
}
.borrower-upload-container{
  min-height: 70px;
  .ant-upload-wrapper{
    .ant-upload {
      @apply rounded-xl bg-transparent border border-dashed border-timidCloud-900 w-full #{!important};
    }
  }
}

.loan-structure-form {
  width: 50%;
 }
 .ant-input-number-group-addon{
     color: black !important;
 }
 .ant-input-number-disabled{
     color: black !important;
 }