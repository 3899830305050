.profile-container {

    padding: 41px 29px;
    background: #ffff;
    border-radius: 20px;
  
  .ant-collapse-header {
    flex-direction: row-reverse;
  }
  
  
  // .ant-collapse {
  //   // background: rgba(196, 193, 202,0.15) !important;
  //   border: 1px solid rgba(138, 133, 149, 0.1) !important;
  //   border-radius: 20px !important;
  //   padding: 26px 24px 25px 24px !important;
  //   margin: 24px 0;
  // }
  
  // .ant-collapse-header-text {
  //   background-color: linear-gradient(270deg, #2b48f4 0%, #34a5ff 100%);
  //   background-image: linear-gradient(270deg, #2b48f4, #34a5ff);
  //   background-size: 100%;
  //   background-repeat: repeat;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   -moz-background-clip: text;
  //   -moz-text-fill-color: transparent;
  //   font-weight: 700;
  //   font-size: 20px;
  // }
  
  // .ant-collapse-content {
  //   border: none !important;
  //   background: #fff !important;
  // }
  
  // .ant-collapse-item {
  //   border: none !important;
  // }
  
  // .ant-collapse-content-box {
  //   padding: 0px !important;
  // }
  
  // .ant-collapse-header {
  //   padding: 0px !important;
  // }
  
  // .ant-collapse-item-active {
  //   background-color: #ffffff !important;
  // }
  
  // .my-collapse.active {
  //   background-color: #fff !important;
  // }
  
  // .my-collapse-two.active {
  //   background-color: #fff !important;
  // }
  
  // .my-collapse-three.active {
  //   background-color: #fff !important;
  // }
  
  
    .ant-tabs-nav {
      padding-left: 35px !important;
    }
    .user-profile {
      display: flex;
      align-items: flex-start;
      position: relative;
      .ant-upload-select {
        border: none !important;
      }
      //     .ant-upload{
      // border: none !important;
      //     }
      .profile-col-two {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        .Individual-user-role {
          border-radius: 38px;
          background: linear-gradient(to right, #edf6fe, #ededfc) border-box;
          width: 110px;
          padding: 10px;
          display: flex;
          justify-content: center;
          span {
            font-weight: 500;
            font-size: 10px;
            background: linear-gradient(
              270deg,
              rgba(43, 72, 244) 0%,
              #34a5ff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        .Institutional-user-role {
          border-radius: 38px;
          background: linear-gradient(to right, rgb(236, 239, 243), rgb(243, 235, 233)) border-box;
          width: 105px;
          padding: 10px;
          display: flex;
          justify-content: center;
          span {
            font-weight: 500;
            font-size: 10px;
            background: linear-gradient(270deg, #dc3d37 0%, #ef8b50 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        h1 {
          font-weight: 700;
          font-size: 20px;
          letter-spacing: 0.02em;
          color: #140a2b;
          margin: 7px 0;
          display: flex;
          align-items: baseline;
          span {
            margin-left: 10px;
            font-size: 12px;
          }
        }
        p {
          font-weight: 500;
          font-size: 14px;
          letter-spacing: -0.045em;
          color: #8a8595;
          margin: 0px;
        }
      }
      .edit-info {
        top: 0;
        right: 28px;
        position: absolute;
      }
    }
  
    .additional-information {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 29px;
      margin-bottom: 30px;
  
      .line {
        width: 1px;
        height: 26px;
        background: #d9d9d9;
        margin-left: 23px;
        margin-right: 27px;
      }
  
      .info {
        p {
          font-weight: 500;
          font-size: 12px;
          color: #8a8595;
        }
  
        h2 {
          margin-top: 6px;
          font-weight: 700;
          font-size: 14px;
          color: #140a2b;
        }
      }
    }
  
    .profile-edit-btn {
      background: rgba(91, 44, 211, 0.1);
      border: 1px solid #5b2cd3;
      border-radius: 50px;
      width: 100px;
      height: 38px;
      font-weight: 500;
      font-size: 16px;
      color: #5b2cd3;
      border: none !important;
  
      svg {
        margin-right: 5px;
        width: 12px;
      }
  
      &:hover {
        color: #5b2cd3 !important;
      }
    }
    .profile-edit-btn3 {
      background: rgba(225, 62, 62, 0.1);
      border: 1px solid #ffd1bd;
      border-radius: 50px;
      width: 100px;
      height: 38px;
      font-weight: 500;
      font-size: 16px;
      color: #e84141;
      border: none !important;
  
      svg {
        margin-right: 5px;
        width: 12px;
      }
  
      &:hover {
        color: #da4343 !important;
      }
    }
    .profile-edit-btn2 {
      background: #a3e7be;
      border: 1px solid #064817;
      border-radius: 50px;
      width: 100px;
      height: 38px;
      font-weight: 500;
      font-size: 16px;
      color: #064817;
      border: none !important;
  
      svg {
        margin-right: 5px;
        width: 12px;
      }
  
      &:hover {
        color: #064817 !important;
      }
    }
  
    .profile-btns {
      display: flex;
      align-items: center;
  
      p {
        font-weight: 500;
        font-size: 16px;
        margin-right: 34px;
        margin-bottom: 0;
        color: #c4c1ca;
        cursor: pointer;
      }
  
      .profile-save-btn {
        width: 100px;
        height: 38px;
      }
    }
  }
  
// info 
.info-panel-container {
    .ant-row {
        margin-top: 16px;
    }

    h1 {
        font-weight: 700;
        font-size: 16px;
        color: #140A2B;
    }

    p {
        font-weight: 500;
        font-size: 16px;
        color: #8A8595;
        margin-bottom: 10px;
    }
}

.info-form-container {
    .infoInput {
        border: 1px solid rgba(138, 133, 149, 0.1);
        background: #fff;
        border-radius: 12px;
        height: 52px;
        // max-width: 210px;
        width: 100%;
        border-radius: 12px;
        padding-top: 15px;
        padding-left: 19px;
        font-weight: 700;
        color: #140A2B;

        &:hover,
        &:focus {
            border: 1px solid #5b2cd3 !important;
        }
    }

    .ant-row {
        margin-top: 10px;
    }

    .label {}

    .as-placeholder {
        left: 20px;
        top: 14px;
    }

    .as-label {
        top: 6px;
        left: 2px;
    }
}