.faqs-page{
    .faqs-icons{
        .delete-icon{
            svg{
                @apply fill-emberGlow-900 ;
            }
        }
        .edit-icon{
            svg{
                @apply fill-primary ;
            }   
        }
        .view-icon{
            svg{
                @apply fill-spanishRoast ;
            }
        }
    }
}
.faqs-form{
    textarea{
        @apply whitespace-normal #{!important};
    }
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    @apply hidden #{!important};
}
