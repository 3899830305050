.wallet-ledger-page{
  .ant-table-wrapper{
    .ant-table{
      @apply rounded-t-lg #{!important};
      .ant-table-thead{
        .ant-table-cell{
          @apply bg-brilliantWhite border-none #{!important};
          .opportunity-table-cell{
            @apply flex flex-col justify-center items-center text-darkShore #{!important};
            span{
              @apply text-darkShore text-xs font-semibold #{!important};
            }
          }
        }
      }
      .ant-table-tbody{
        .ant-table-cell{
          @apply text-flintstone text-sm  leading-[0.875rem] font-medium #{!important};
        }
      }
    }
  }
  .wallet-info-wrapper{
    display: flex;
  }
  .additional-information {
    &.wallet {
        flex-direction: column;
        justify-content:flex-start!important;
        display: inline-flex!important;
        align-items: flex-start!important;
  
        .info {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: flex-start;
  
            p {
                margin-bottom: 0;
            }
        }
    }
  }
}



.card-p-0{
  .ant-card-body{
    @apply p-0 #{!important};
  }
}
// grid
.col-5{
  display: block;
  flex: 0 0 20%;
  max-width: 20%;
}