@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body {
    background: #f8f8f8;
    font-family: "Inter", sans-serif;
}

.ant-spin-dot-item {
    @apply bg-primary #{!important};
  }

.ant-btn {
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    padding: 7px 30px;
    min-width: 100px;
    &.ant-btn-block {
        padding: 18px 30px;
        font-size: 14px;
    }
    &.ant-btn-blue {
        background: #2b48f4;
        color: #fff;
    }
    &.ant-btn-gray {
        background: #4b5563;
        color: #fff;
    }
    &.ant-btn-outline-black {
        color: #000;
        border: 1px solid #000;
        &:hover {
            background: #000;
            color: #fff;
        }
    }
    &.ant-btn-text-purple {
        color: #5b2cd3;
    }
    &.width-auto {
        min-width: max-content;
        padding: 3px;
    }
}

.ant-tabs-nav-wrap .ant-tabs-ink-bar {
    max-width: 100% !important;
}

.auth-page {
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: #fff;
    .image-section {
        width: 65%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .main-section {
        display: flex;
        flex-direction: column;
        flex: auto;
        justify-content: space-between;
        main {
            flex: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .auth-body {
                max-width: 345px;
                width: 100%;
            }
        }
        footer {
            text-align: center;
            padding: 30px 15px;
        }
    }
}

.auth-body {
    h3 {
        color: #8a8595;
        font-size: 20px;
        font-weight: 400;
        margin: 22px 0 0;
    }
    h2 {
        margin: 0;
        color: #140a2b;
        font-size: 35px;
        font-weight: 700;
    }
    form {
        margin-top: 30px;
    }
}

.float-label {
    position: relative;
    margin: 0 0 20px;
    label {
        position: absolute;
        left: 20px;
        top: 50%;
        font-size: 16px;
        color: #c4c1ca;
        transform: translateY(-50%);
        &.label-float {
            top: 30%;
            font-size: 10px;
        }
    }
    .ant-input-affix-wrapper {
        border-radius: 100px;
        overflow: hidden;
        background: transparent;
        .ant-input {
            padding: 3px 12px 4px 6px !important;
        }
        + label {
            left: 35px;
        }
    }
    .ant-input {
        padding: 3px 12px 0px 18px !important;
        height: 55px;
        background: transparent;
        border-radius: 100px;
    }

    .ant-select .ant-select-selector {
        padding: 16px 10px 4px 11px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 16px 10px 4px 11px;
        height: 48px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
        top: 16px;
    }
}

.gen-page {
    .main-section {
        padding: 0 15px;
        height: calc(100vh - 130px);
        overflow: auto;
        @extend %base-scroll;
        .sidebar {
            width: 240px;
            background: #ffffff;
            border-radius: 20px;
            padding: 45px 20px;
            position: fixed;
            left: 15px;
            top: 99px;
            height: calc(100vh - 60px);
        }
        main {
            padding-left: 300px;
            height: 100%;
        }
    }
}

.menu-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-dropdown-trigger {
        p {
            font-size: 13px;
            color: #a3a3a3;
            line-height: 1.125;
            strong {
                display: block;
                color: #000;
                font-size: 16px;
                font-weight: 700;
            }
        }
        .ant-badge-dot {
            width: 12px;
            height: 12px;
            top: 9px;
            right: 8px;
            border: 2px solid #fff;
            background: #464646;
        }
        .ant-avatar {
            padding: 3px;
            border: 2px solid #ebebeb;
        }
    }
    .ant-dropdown,.ant-dropdown-menu{
        display: none !important;
    }
}

header {
    padding: 17px 0;
    background: #fff;
    margin: 0 0 15px;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sidebar-body {
        height: calc(100% - 106px);
        overflow: auto;
        @extend %base-scroll;
    }
    .sidebar-items {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
    .ant-menu {
        border: 0 !important;
        .ant-menu-item {
            padding: 10px 20px;
            line-height: normal;
            border-left: 6px solid transparent;
            color: #a9a9a9;
            border-radius: 6px;
            margin: 8px 0;
            font-size: 14px;
            .icon {
                margin-right: 5px;
                .anticon {
                    font-size: 18px;
                }
            }
            &.ant-menu-item-selected {
                background: #f2f2f2;
                font-weight: 700;
                color: #4b5563;
                border-color: #4b5563;
            }
        }
    }

    .sidebar-footer {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #e3e3e3;
    }
}

.round-div {
    // margin-top: 45px;
    background: #ffffff;
    border-radius: 20px;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    height: 100%;
    overflow: auto;
    @extend %base-scroll;
    .rounded-header {
        padding: 16px 20px 0px;
        h2 {
            margin: 0;
            font-size: 18px;
        }
    }
    .rounded-body {
        .ant-tabs-nav-wrap {
            padding-left: 20px;
            .ant-tabs-tab {
                color: #666666;
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: #000;
                    }
                }
            }
            .ant-tabs-ink-bar {
                background: #000;
                max-width: 30%;
                height: 3px;
                border-radius: 100px;
            }
        }
        .selection-tag {
            padding: 0px 16px;
            margin: 0 0 10px;
        }
    }
}


.request-section {
    border-top: 1px solid #d9d9d9;
    padding: 35px 25px;
    color: #000;
    h2 {
        font-size: 16px;
        font-weight: 700;
    }
    h3 {
        font-size: 16px;
        font-weight: 600;
    }
}

.color-gray {
    color: #a3a3a3;
}

.g-info {
    padding: 32px 50px;
    .back-info {
        .ant-btn {
            min-width: max-content;
        }
    }
    form {
        padding-top: 20px;
        border-top: 1px solid;
        label {
            font-weight: 600;
        }
    }
}

// .ant-radio-group {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 20px;
//     .ant-radio-button-wrapper {
//         width: calc(50% - 10px);
//         border-radius: 100px;
//         border: 1px solid #dcdde5;
//         text-align: center;
//         padding: 8px 10px;
//         height: auto;
//         &:before {
//             content: none;
//         }
//     }
// }
.ant-table{
    overflow: auto;
}
.offer-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 15px;
}

.compnay-detail {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #a9a9a9;
    .title {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
    }
}

.doc-tab {
    border: 1px solid #dcdde5;
    border-radius: 12px;
    padding: 13px 17px;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}
.ant-table-wrapper {
    .ant-table-thead > tr > th {
        background: transparent;
        color: #5b2cd3;
        font-size: 14px;
        font-weight: 700;
        padding: 16px 10px;
        &:before {
            content: none !important;
        }
    }
}

.fund-search-button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: auto;
    height: 40px;
    background: #6515dc;
    border: 1px solid #6515dc;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin-left: 4px;
}
.fund-check-button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 105px;
    height: 40px;
    background: #4b5563;
    border: 1px solid #4b5563;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
}

.fund-transfer-button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 100px;
    height: 40px;
    background: #4b5563;
    border: 1px solid #4b5563;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
}
.loan-request-status {
    height: 28px;
    width: 95px;
    font-weight: 700;
    border-radius: 17px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.document-view-container {
    border: 1px solid #dcdde5;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    width: 300px;

    svg {
        &:hover {
            cursor: pointer;
        }
    }
}

.ant-input{
    @apply truncate #{!important}
}

.switch-btn-style {
    background:#b6b6b6
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled),
.switch-btn-style.ant-switch-checked{
    background:#6515dc
}

.radio-as-tab {
    label {
        border-width: 0 0 2px 0!important;
        border-radius: 0!important;
        border-color: #f0f0f0;
        background: transparent!important;
        &:before{
            content: unset!important
        }
        &.ant-radio-button-wrapper-checked{
            background: transparent!important;
            color: #4b5563 !important;
            font-weight: bold;
        }
    }
}
.tag-status{
    @apply  font-semibold rounded-3xl;
    &__pending{
        @apply bg-yellow-400 border-yellow-400 
    }
    &__rejected{
        @apply bg-red-400 border-red-400
    }
    &__accepted{
        @apply bg-green-400 border-green-400
    }
    &__waiting-approval{
        @apply bg-darkGrey border-darkGrey text-white
    }
    &__active{
        @apply bg-hawaiiMorning border-hawaiiMorning 
    }
    &__completed{
        @apply bg-primary border-primary text-white
    }
}

.small-doc-view{
    
    span{
        @apply text-xs #{!important}
    }
    .document-view-container{
        width: 227px;
    }
    
}

.img-upload {
    min-height: 110px;
  }
  
  .img-upload .ant-upload-list-picture-card > div:nth-child(2) {
    display: none;
  }

// layout 
.sidebar-body{
    .ant-menu-item{
        @apply px-2 #{!important}
    }
}

.ant-select-tree-list-holder{
    @apply overflow-y-auto #{!important}
}
.ant-select-tree-list-scrollbar-thumb{
    @apply hidden #{!important}
}
.withdraw-form{
    .ant-form-item-control-input-content{
        @apply flex #{!important}
    }
}
.owners-otps{
    label{
        @apply block #{!important}
    }
}
textarea.ant-input{
    @apply whitespace-normal #{!important};
}
// status styling 
.transaction-status {
    border: 1px solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
}

.status-Initiated,
.status-initiated {
    background-color: #DAE0F6;
    color: #022CC7;
    border-color: #022CC7;
}

.status-Pending,
.status-pending {
    background-color: #FFF9CD;
    color: #8F842F;
    border-color: #8F842F;
}

.status-Failed,
.status-failed {
    background-color: #FFE4E4;
    color: #FF0000;
    border-color: #FF0000;
}

.status-Completed,
.status-completed {
    background-color: #F1FBEF;
    color: #00B427;
    border-color: #00B427;
}

.status-Processed,
.status-processed {
    background-color: #FBF7EF;
    color: #FF7700;
    border-color: #FF7700;
}

.status-Approved,
.status-approved {
    background-color: #F8EFFB;
    color: #7B00B4;
    border-color: #7B00B4;
}

.status-Rejected,
.status-rejected {
    background-color: #EBEBEB;
    color: #000000;
    border-color: #000000;
}

.ant-input-group-addon{
    @apply bg-[#4b5563] text-white #{!important};
}