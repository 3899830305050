@tailwind base;
@tailwind components;
@tailwind utilities;
// pages 
@import "./pages/opportunity";
@import "./pages/wallets.scss";
@import "./pages/faqs.scss";
@import "./pages/loanReq.scss";
@import "./pages/borrowers.scss";
@import "./pages/investors.scss";

// shared pages
@import "./shared/profile.scss";
@import "./forms/createLoan.scss";

// others
@import "./general";
@import "./loans";
@import "./helper";
@import "./scroll";