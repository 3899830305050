.loan-details-modal {
    .loan-details-header {
        display: "flex";
        justify-content: "center";
        align-items: "center";
        color: #5b2cd3;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    .loan-key {
        color: #000000e0;
        font-weight: 500;
        font-size: 16px;
    }

    .loan-value {
        color: #8a8595;
        font-size: 14px;
    }
}

// loan details
.loan-details-page {
    .edit-icon{
        svg{
            @apply text-hawaiiMorning text-sm #{!important};
        }
    }
}
// edit loan form 
.installment-edit-form{
    .remove-icon{
        svg{
            @apply text-black #{!important};
        }
    }
}