.container {
    width: 99%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.container-xl {
    width: 100%;
    max-width: calc(100% - 130px);
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.fw-700 {
    font-weight: 700 !important;
}

.text-purple {
    color: #5b2cd3 !important;
}

.p-0 {
    padding: 0 !important;
}

.my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.m-0 {
    margin: 0;
}

.mt-12 {
    margin-top: 12px !important;
}

.w-100 {
    width: 100%;
}

.h-full {
    height: 100%;
}

.ml-auto {
    margin-left: auto;
}

.text-right {
    text-align: right;
}
