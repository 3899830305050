.add-opportunity-container{
  h1{
      text-align: center;
      text-decoration: underline;
  }
  h2{
      font-size: 18px
  }
}
.ant-picker-ok{
  .ant-btn{
    @apply bg-darkGrey text-white #{!important};
  }
}
.opportunity-status-options.hide-defaulted{
  .rc-virtual-list-holder-inner{
    div:nth-child(4) {
      @apply hidden;
    }
  }
}