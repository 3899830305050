.avatar-uploader {
  width: 82.34px !important;
  height: 82.34px !important;
  border-radius: 50% !important;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #2b48f4, #34a5ff) border-box;
  border: 1px solid transparent;
  position: relative;
  z-index: 10;
  // width: 48px;
  // height: 48px;
}
.upload-icon {
  position: absolute;
  bottom: -7px;
  right: 0px;
  z-index: 100;
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border: 4px solid transparent;
}
.custom_file_wraper .avatar-uploader {
  display: none;
}
.custom_file_wraper .avatar {
  width: 81px;
  height: 81px;
  max-width: 81px;
  border-radius: 50px;
  object-fit: cover;
}
.custom_file_wraper {
  position: relative;
}
.custom_file_wraper .upload-icon {
  position: absolute;
  left: 60px;
  bottom: -90px;
  cursor: pointer;
}
.profile-container .user-profile .profile-col-two {
  margin-left: 100px !important;
}
.borrower-user-image{
  .custom_file_wraper {
    .avatar{
      max-width: 81px;
    }
  }
}
